export const orderStatus = [
  {
    code: 0,
    label: 'canceled',
    labelIcon: 'eva:close-circle-fill',
    group: 'canceled',
    step: 'cancel',
    stepAction: 'orderCanceled',
    action: '',
    actionIcon: '' 
  },
  {
    code: 1,
    label: 'received',
    labelIcon: 'eva:shopping-cart-fill',
    group: 'active',
    step: 'receive',
    stepAction: 'orderReceived',
    action: 'prepare',
    actionIcon: 'mdi:delete-variant' 
  },
  {
    code: 2,
    label: 'preparing',
    labelIcon: 'eva:clock-fill',
    group: 'active',
    step: 'startPrep',
    stepAction: 'startedPreparation',
    action: `setReady`,
    actionIcon: 'eva:bell-fill' 
  },
  {
    code: 3,
    label: 'ready',
    labelIcon: 'eva:bell-fill',
    group: 'active',
    step: 'finishPrep',
    stepAction: 'finishedPreparation',
    action: 'deliver',
    actionIcon: 'mdi:glass-cocktail' 
  },
  {
    code: 4,
    label: 'completed',
    labelIcon: 'eva:checkmark-circle-2-fill',
    group: 'inactive',
    step: 'complete',
    stepAction: 'delivered',
    action: '',
    actionIcon: '' 
  },
];