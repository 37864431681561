import { tokens } from "../tokens";

// ----------------------------------------------------------------------

const en = {
  [tokens.alerts.attention]: 'Attention',
  [tokens.alerts.error]: 'Error',
  [tokens.alerts.success]: 'Success',
  [tokens.alerts.warning]: 'Warning',
  [tokens.buttons.accept]: 'Accept',
  [tokens.buttons.add]: 'Add',
  [tokens.buttons.addTo]: 'Add to',
  [tokens.buttons.addToAlt]: 'Add to',
  [tokens.buttons.back]: 'Back',
  [tokens.buttons.bulkEdit]: 'Bulk edit',
  [tokens.buttons.bulkStockEntry]: 'Bulk stock entry',
  [tokens.buttons.cancel]: 'Cancel',
  [tokens.buttons.cancel]: 'Change',
  [tokens.buttons.clearFilter]: 'Clear filters',
  [tokens.buttons.create]: 'Create',
  [tokens.buttons.confirm]: 'Confirm',
  [tokens.buttons.continue]: 'Continue',
  [tokens.buttons.delete]: 'Delete',
  [tokens.buttons.deliver]: 'Deliver to client',
  [tokens.buttons.edit]: 'Edit',
  [tokens.buttons.filter]: 'Filter',
  [tokens.buttons.finish]: 'Finish',
  [tokens.buttons.invite]: 'Invite',
  [tokens.buttons.new]: 'New',
  [tokens.buttons.newAlt]: 'New',
  [tokens.buttons.next]: 'Next',
  [tokens.buttons.reconnect]: 'Reconnect',
  [tokens.buttons.record]: 'Record',
  [tokens.buttons.rememberAcc]: 'Remember account',
  [tokens.buttons.resendCodeEmail]: 'Resend code',
  [tokens.buttons.resendVerifEmail]: 'Resend verification email',
  [tokens.buttons.prepare]: 'Prepare',
  [tokens.buttons.previous]: 'Previous',
  [tokens.buttons.save]: 'Save',
  [tokens.buttons.select]: 'Select',
  [tokens.buttons.setReady]: 'Ready for pick up',
  [tokens.buttons.show]: 'Show',
  [tokens.buttons.showMore]: 'Show more',
  [tokens.buttons.submit]: 'Submit',
  [tokens.buttons.sync]: 'Sync',
  [tokens.buttons.update]: 'Update',
  [tokens.buttons.upload]: 'Upload',
  [tokens.buttons.verify]: 'Verify',
  [tokens.buttons.view]: 'View',
  [tokens.calendar.day]: 'Day',
  [tokens.calendar.week]: 'Week',
  [tokens.calendar.month]: 'Month',
  [tokens.calendar.year]: 'Year',
  [tokens.charts.activeSale]: 'Active Sale',
  [tokens.charts.averagePublic]: 'Average Public',
  [tokens.charts.averageTicket]: 'Average Ticket',
  [tokens.charts.monthlyComparison]: '{{ value }} than last month',
  [tokens.charts.publicDistribution]: 'Public Distribution',
  [tokens.charts.publicAgeDistribution]: 'Public Age Range this',
  [tokens.charts.saleIncome]: 'Sale Income',
  [tokens.charts.salesOverview]: 'Sales Overview',
  [tokens.charts.salesPerVenue]: 'Sales per Venue',
  [tokens.charts.productSalesPerCategory]: 'Products Sold by Category',
  [tokens.charts.productSalesRanking]: 'Product Sales Ranking',
  [tokens.charts.weeklyComparison]: '{{ value }} than last week',
  [tokens.common.all]: 'All',
  [tokens.common.allowed]: 'Allowed',
  [tokens.common.authorized]: 'Authorized',
  [tokens.common.browse]: 'Browse',
  [tokens.common.bulk]: 'Bulk',
  [tokens.common.date]: 'Date',
  [tokens.common.entry]: 'Entry',
  [tokens.common.exit]: 'Exit',
  [tokens.common.history]: 'History',
  [tokens.common.languageChanged]: 'Language changed',
  [tokens.common.maxSize]: 'Maximum size',
  [tokens.common.notAllowed]: 'Not allowed',
  [tokens.common.notes]: 'Notes',
  [tokens.common.or]: 'or',
  [tokens.common.question]: 'Question',
  [tokens.common.recommended]: 'Recommended',
  [tokens.common.selected]: 'Selected',
  [tokens.common.source]: 'Source',
  [tokens.common.to]: 'to',
  [tokens.common.unauthorized]: 'Unauthorized',
  [tokens.common.with]: 'with',
  [tokens.components.fileUpload.header]: 'Upload file',
  [tokens.components.fileUpload.subStart]: 'Drop file here or click',
  [tokens.components.fileUpload.subEnd]: 'from your computer',
  [tokens.components.photoUpload.header]: 'Upload photos',
  [tokens.components.photoUpload.subStart]: 'Drop photos here or click',
  [tokens.components.photoUpload.subEnd]: 'from your computer',
  [tokens.components.filter]: 'Filter',
  [tokens.components.search]: 'Search',
  [tokens.components.photo]: 'Photo',
  [tokens.controls.createdAt]: 'Created at',
  [tokens.controls.createdBy]: 'Created by',
  [tokens.controls.orderHistory]: 'Order history',
  [tokens.controls.updatedAt]: 'Updated at',
  [tokens.controls.updatedBy]: 'Updated by',
  [tokens.fields.address]: 'Address',
  [tokens.fields.addressProof]: 'Proof of Address',
  [tokens.fields.amount]: 'Amount',
  [tokens.fields.area]: 'Area',
  [tokens.fields.bankingProof]: 'Proof of Banking Account',
  [tokens.fields.categoryCode]: 'Category code',
  [tokens.fields.categorySku]: 'Category SKU',
  [tokens.fields.city]: 'City',
  [tokens.fields.code]: 'Code',
  [tokens.fields.comment]: 'Comment',
  [tokens.fields.complement]: 'Complement',
  [tokens.fields.companyContract]: 'Company Contract',
  [tokens.fields.cost]: 'Cost',
  [tokens.fields.country]: 'Country',
  [tokens.fields.description]: 'Description',
  [tokens.fields.discount]: 'Discount',
  [tokens.fields.districtCode]: 'District Code',
  [tokens.fields.districtId]: 'District ID',
  [tokens.fields.drinks]: 'Drinks',
  [tokens.fields.email]: 'Email',
  [tokens.fields.entranceFee]: 'Entrance Fee',
  [tokens.fields.federalId]: 'Federal ID',
  [tokens.fields.federalIdCard]: 'Federal ID Card',
  [tokens.fields.fiscalRegime]: 'Fiscal Regime',
  [tokens.fields.firstName]: 'First name',
  [tokens.fields.from]: 'From',
  [tokens.fields.food]: 'Food',
  [tokens.fields.gender]: 'Gender',
  [tokens.fields.lastName]: 'Last name',
  [tokens.fields.legalName]: 'Legal Name',
  [tokens.fields.manager]: 'Manager',
  [tokens.fields.minAmount]: 'Minimum Order',
  [tokens.fields.name]: 'Name',
  [tokens.fields.notVerified]: 'Not verified',
  [tokens.fields.number]: 'Number',
  [tokens.fields.openStatus]: 'Open status',
  [tokens.fields.password]: 'Password',
  [tokens.fields.placeholders.description]: 'Quick description of your venue that will be available to your clients...',
  [tokens.fields.phone]: 'Phone number',
  [tokens.fields.price]: 'Regular Price',
  [tokens.fields.productCode]: 'Product code',
  [tokens.fields.productNcm]: 'Product NCM',
  [tokens.fields.productSku]: 'Product SKU',
  [tokens.fields.profileAvatar]: 'Profile Photo',
  [tokens.fields.quantity]: 'Quantity',
  [tokens.fields.salePrice]: 'Sale price',
  [tokens.fields.sale]: 'Sale',
  [tokens.fields.sku]: 'SKU',
  [tokens.fields.stock]: 'Stock',
  [tokens.fields.stockQuantity]: 'Quantity in stock',
  [tokens.fields.stockThreshold]: 'Stock Threshold',
  [tokens.fields.state]: 'State',
  [tokens.fields.status]: 'Status',
  [tokens.fields.stateId]: 'State ID',
  [tokens.fields.street]: 'Street',
  [tokens.fields.subtotal]: 'Subtotal',
  [tokens.fields.tableNumber]: 'Table Number',
  [tokens.fields.taxes]: 'Taxes',
  [tokens.fields.to]: 'To',
  [tokens.fields.total]: 'Total',
  [tokens.fields.underVerification]: 'Under verification',
  [tokens.fields.unitPrice]: 'Unit price',
  [tokens.fields.verified]: 'Verified',
  [tokens.fields.venueType]: 'Type',
  [tokens.fields.weekday]: 'Weekday',
  [tokens.fields.zipCode]: 'Zip Code',
  [tokens.gender.female]: 'Female',
  [tokens.gender.male]: 'Male',
  [tokens.gender.unidentified]: 'Unidentified',
  [tokens.helper.higherThan]: '{{ field }} should be higher than {{ value }}',
  [tokens.helper.invalid]: 'Invalid',
  [tokens.helper.invalidEmail]: 'Must be a valid email address',
  [tokens.helper.isRequired]: 'is required',
  [tokens.helper.lowerThan]: '{{ field }} should be lower than {{ value }}',
  [tokens.helper.minChar]: '{{ field }} must be at least {{ count }} characters',
  [tokens.helper.minChar]: '{{ field }} must be at least {{ count }} caracteres',
  [tokens.helper.minLower]: '{{ field }} must be at least {{ count }} lowercase letter',
  [tokens.helper.minNumber]: '{{ field }} must be at least {{ count }} number',
  [tokens.helper.minSymbol]: '{{ field }} must be at least {{ count }} symbol',
  [tokens.helper.minUpper]: '{{ field }} must be at least {{ count }} uppercase letter',
  [tokens.helper.noMatch]: `doesn't match`,
  [tokens.helper.noName]: '{{ field }} must not contain {{ notAllowed }}',
  [tokens.helper.noSeqNumber]: '{{ field }} must not contain sequential numbers',
  [tokens.helper.noSpace]: '{{ field }} must not contain space',
  [tokens.helper.notNegative]: '{{ field }} must not be negative',
  [tokens.joyride.tour]: 'Restart Tour',
  [tokens.joyride.dashboard.openVenuesCard.title]: 'Quick Access to Venues',
  [tokens.joyride.dashboard.openVenuesCard.content]: 'View which venues are currently open.',
  [tokens.joyride.dashboard.salesOverviewCard.title]: 'Sales Summary',
  [tokens.joyride.dashboard.salesOverviewCard.content]: 'View how your consolidaded sales are for all your venues',
  [tokens.joyride.inventoryPage.title]: 'Inventory Management',
  [tokens.joyride.inventoryPage.content]: 'Add, view statement and manage the inventory of all your venues.',
  [tokens.joyride.invoicesPage.title]: 'Invoices Management',
  [tokens.joyride.invoicesPage.content]: 'Centralized access to all your invoices from all your venues.',
  [tokens.joyride.menuPage.title]: 'Global Menu Management',
  [tokens.joyride.menuPage.content]: 'Centralized registration of categories and products of your menus.',
  [tokens.joyride.ordersPage.title]: 'Orders Management',
  [tokens.joyride.ordersPage.content]: 'Centralized acess to all your orders from all your venues.',
  [tokens.joyride.usersPage.title]: 'Users Management',
  [tokens.joyride.usersPage.content]: 'Create, edit and manage all your users and permissions to venues.',
  [tokens.joyride.venuesPage.title]: 'Venues Management',
  [tokens.joyride.venuesPage.content]: 'Create, edit and manage all venues and its informations such as address, schedule, menu, inventory and security.',
  [tokens.kpi.ranking]: 'Ranking',
  [tokens.kpi.totalIncome]: 'Total Income',
  [tokens.nav.about]: 'About us',
  [tokens.nav.academy]: 'Academy',
  [tokens.nav.account]: 'Account',
  [tokens.nav.activeOrders]: 'Active Orders',
  [tokens.nav.activeOrdersList]: 'Active Orders List',
  [tokens.nav.app]: 'App',
  [tokens.nav.analytics]: 'Analytics',
  [tokens.nav.auth]: 'Auth',
  [tokens.nav.banking]: 'Banking',
  [tokens.nav.blog]: 'Blog',
  [tokens.nav.booking]: 'Booking',
  [tokens.nav.brokers]: 'Brokers',
  [tokens.nav.browse]: 'Browse',
  [tokens.nav.calendar]: 'Calendar',
  [tokens.nav.canceledOrders]: 'Canceled Orders',
  [tokens.nav.canceledOrdersList]: 'Canceled Orders List',
  [tokens.nav.cards]: 'Cards',
  [tokens.nav.category]: 'Category',
  [tokens.nav.categories]: 'Categories',
  [tokens.nav.categoryList]: 'Categories List',
  [tokens.nav.chat]: 'Chat',
  [tokens.nav.checkout]: 'Checkout',
  [tokens.nav.concepts]: 'Concepts',
  [tokens.nav.contact]: 'Contact',
  [tokens.nav.course]: 'Course',
  [tokens.nav.create]: 'Create',
  [tokens.nav.crypto]: 'Crypto',
  [tokens.nav.customer]: 'Customer',
  [tokens.nav.customers]: 'Customers',
  [tokens.nav.dashboard]: 'Dashboard',
  [tokens.nav.details]: 'Details',
  [tokens.nav.docs]: 'Documentation',
  [tokens.nav.ecommerce]: 'E-Commerce',
  [tokens.nav.economicGroup]: 'Economic Group',
  [tokens.nav.edit]: 'Edit',
  [tokens.nav.error]: 'Error',
  [tokens.nav.feed]: 'Feed',
  [tokens.nav.fileManager]: 'File Manager',
  [tokens.nav.files]: 'Files',
  [tokens.nav.finance]: 'Finance',
  [tokens.nav.fiscal]: 'Fiscal',
  [tokens.nav.fleet]: 'Fleet',
  [tokens.nav.forgotPassword]: 'Forgot Password',
  [tokens.nav.general]: 'General',
  [tokens.nav.globalMenu]: 'Global Menu',
  [tokens.nav.globalProduct]: 'Global Product',
  [tokens.nav.inactiveOrders]: 'Inactive Orders',
  [tokens.nav.inactiveOrdersList]: 'Inactive Orders List',
  [tokens.nav.inventory]: 'Inventory',
  [tokens.nav.inventoryControl]: 'Inventory Control',
  [tokens.nav.inventoryStatement]: 'Inventory Statement',
  [tokens.nav.invoice]: 'Invoice',
  [tokens.nav.invoiceDetails]: 'Invoice Details',
  [tokens.nav.invoices]: 'Invoices',
  [tokens.nav.invoiceKanban]: 'Invoices Kanban',
  [tokens.nav.invoiceList]: 'Invoices List',
  [tokens.nav.jobList]: 'Job Listings',
  [tokens.nav.kanban]: 'Kanban',
  [tokens.nav.list]: 'List',
  [tokens.nav.login]: 'Login',
  [tokens.nav.logistics]: 'Logistics',
  [tokens.nav.logout]: 'Logout',
  [tokens.nav.mail]: 'Mail',
  [tokens.nav.management]: 'Management',
  [tokens.nav.menu]: 'Menu',
  [tokens.nav.menus]: 'Menus',
  [tokens.nav.notifications]: 'Notifications',
  [tokens.nav.order]: 'Order',
  [tokens.nav.orders]: 'Orders',
  [tokens.nav.orderDetails]: 'Order Details',
  [tokens.nav.orderList]: 'Order List',
  [tokens.nav.overview]: 'Overview',
  [tokens.nav.pages]: 'Pages',
  [tokens.nav.partners]: 'Partners',
  [tokens.nav.postCreate]: 'Post Create',
  [tokens.nav.postDetails]: 'Post Details',
  [tokens.nav.postList]: 'Post List',
  [tokens.nav.pricing]: 'Pricing',
  [tokens.nav.privacyPolicy]: 'Privacy Policy',
  [tokens.nav.product]: 'Product',
  [tokens.nav.products]: 'Products',
  [tokens.nav.productList]: 'Products List',
  [tokens.nav.profile]: 'Profile',
  [tokens.nav.recoverPassword]: 'Recover password',
  [tokens.nav.register]: 'Register',
  [tokens.nav.reports]: 'Reports',
  [tokens.nav.resetPassword]: 'Reset password',
  [tokens.nav.role]: 'Role',
  [tokens.nav.roles]: 'Roles',
  [tokens.nav.roleList]: 'Roles List',
  [tokens.nav.schedule]: 'Schedule',
  [tokens.nav.security]: 'Security',
  [tokens.nav.settings]: `Settings`,
  [tokens.nav.signUp]: 'Sign Up',
  [tokens.nav.socialMedia]: 'Social Media',
  [tokens.nav.statement]: 'Statement',
  [tokens.nav.stockEntry]: 'Stock entry',
  [tokens.nav.supplier]: `Supplier`,
  [tokens.nav.terms]: 'Terms and Conditions',
  [tokens.nav.table]: 'Table',
  [tokens.nav.tables]: 'Tables',
  [tokens.nav.verifyCode]: 'Verify Code',
  [tokens.nav.verifyEmail]: 'Verify Email',
  [tokens.nav.venue]: 'Venue',
  [tokens.nav.venues]: 'Venues',
  [tokens.nav.venueList]: 'Venues List',
  [tokens.nav.venueScheduleTable]: 'Venues Schedules',
  [tokens.nav.user]: 'User',
  [tokens.nav.users]: 'Users',
  [tokens.nav.userList]: 'Users List',
  [tokens.pages.app.bannerHeader]: 'Welcome back',
  [tokens.pages.app.bannerSubtitle]: `If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything.`,
  [tokens.pages.app.bannerCTA]: 'Show',
  [tokens.pages.globalMenu.dialogSubtitle]: `Select products to add to this venue's menu`,
  [tokens.pages.invoice.codeDialogHeader]: 'Enter delivery verification code',
  [tokens.pages.invoice.codeDialogText]: 'Please ask the customer for the 4-digit verification code',
  [tokens.pages.invoice.leftFootnote]: 'This receipt has no tax value',
  [tokens.pages.invoice.reminderDialogAlert] : 'The customer will be notified of the order is',
  [tokens.pages.invoice.reminderDialogHeader]: 'Remind Client',
  [tokens.pages.invoice.reminderDialogSubtitle]: `We can notify the client once again if the order wasn't pick up yet.`,
  [tokens.pages.invoice.reminderDialogText]: 'Do you wish to continue?',
  [tokens.pages.invoice.rightFootnote]: 'suporte@checktech.app',
  [tokens.pages.invoice.updateDialogAlert]: 'The customer will be notified of the',
  [tokens.pages.invoice.updateDialogAlertStrong]: 'updated order status',
  [tokens.pages.invoice.updateDialogHeader]: 'Update Order Status',
  [tokens.pages.invoice.updateDialogText]: 'Please confirm the change to the next status',
  [tokens.pages.login.formHeader]: 'Sign in to Check',
  [tokens.pages.login.formSubtitle]: 'Sign in with your data',
  [tokens.pages.login.noAccountText]: `Don't have an account yet?`,
  [tokens.pages.login.sideBanner]: 'Hi, welcome back!',
  [tokens.pages.product.inventoryDescription]: `Set the product's stock threshold so we can let you know when it's running low`,
  [tokens.pages.product.switchDescription]: `Leave off to {{ action }} this product without affecting venues' menus`,
  [tokens.pages.register.formHeader]: 'Sign up to Check',
  [tokens.pages.register.formSubtitle]: 'Your new cashless e cardless software',
  [tokens.pages.register.formFooterStart]: `By signing up, I confirm I've read and agree with Check's`,
  [tokens.pages.register.formFooterEnd]: '',
  [tokens.pages.register.existingAccountText]: 'Already have an account?',
  [tokens.pages.register.sideBanner]: 'Optimize your operation with Check',
  [tokens.pages.signUp.formHeader]: 'Want to have Check on your Venue?',
  [tokens.pages.signUp.formSubtitle]: `Access is currently limited to invited partners. Join the waitlist bellow. We'll get in touch when is time to optimize your operation!`,
  [tokens.pages.signUp.formFooterStart]: `By joining the waitlist, I confirm I've read and agree with Check's`,
  [tokens.pages.signUp.formFooterEnd]: '',
  [tokens.pages.signUp.existingAccountText]: 'Already have an account?',
  [tokens.pages.signUp.sideBanner]: 'Become a Check Partner!',
  [tokens.pages.resetPwrd.formHeader]: 'Forgot your password?',
  [tokens.pages.resetPwrd.formSubtitle]: `Please type the e-mail associated with your accont and we'll send you a link to reset your password.`,
  [tokens.pages.schedule.formAlert]: 'The venue will automatically open and close to the public with this configuration. You can manually change the open status on tab',
  [tokens.pages.schedule.formHeader]: 'Business Schedule Details',
  [tokens.pages.schedule.formSubtitle]: 'Define your business schedule and client/public service.',
  [tokens.pages.schedule.closesAt]: 'Closes at',
  [tokens.pages.schedule.openUntil]: 'Open until',
  [tokens.pages.schedule.opensAt]: 'Opens at',
  [tokens.pages.userProfile.general.scheduleSwitch]: `Sync with venue's business schedule`,
  [tokens.pages.userProfile.general.scheduleSwitchTooltip]: `User's access will be disabled during off (closed) hours`,
  [tokens.pages.userProfile.general.scheduleSwitchDisabledTooltip]: 'Unable to sync this user role',
  [tokens.pages.userProfile.general.statusSwitch]: `Inactivate user's access`,
  [tokens.pages.userProfile.general.statusSwitchDisabledTooltip]: `Unable to inactivate your own access`,
  [tokens.pages.userProfile.general.roleDialogHeader]: '{{ role }} Profile Permissions',
  [tokens.pages.userProfile.general.roleDialogSubtitle]: '',
  [tokens.pages.userProfile.security.altHeader]: 'Unable to modify your own access to venues',
  [tokens.pages.userProfile.security.header]: 'Setup which venues {{ name }} can access',
  [tokens.pages.userProfile.security.listAltHeader]: 'Authorized Venues',
  [tokens.pages.userProfile.security.listHeader]: 'Unauthorized Venues',
  [tokens.pages.venues.noData]: 'No venues found',
  [tokens.pages.venues.noDataTitle]: 'Start by registering your first!',
  [tokens.pages.venues.bulkEditDialogHeader]: 'Change Selected Venues',
  [tokens.pages.venues.bulkEditDialogSubtitle]: 'Please select which fields you want to update for all selected venues and set the desired status.',
  [tokens.pages.venueProfile.docs.header]: 'Documentation for verification',
  [tokens.pages.venueProfile.docs.subtitle]: 'Send the required documentation to verify the venue',
  [tokens.pages.venueProfile.fiscal.header]: 'Fiscal Details',
  [tokens.pages.venueProfile.fiscal.addressCheck]: 'Registered Address',
  [tokens.pages.venueProfile.fiscal.addressCheckSub]: 'Use same as business address',
  [tokens.pages.venueProfile.general.header]: 'Basic Details',
  [tokens.pages.venueProfile.general.openstatusSwitch]: 'Manually open venue to the public',
  [tokens.pages.venueProfile.general.openstatusSwitchTooltip]: 'When open the public can check-in and send orders',
  [tokens.pages.venueProfile.general.statusSwitch]: 'Apply to make this venue viewable to the public',
  [tokens.pages.venueProfile.general.statusSwitchTooltip]: 'When active the venue is visible to the public',
  [tokens.pages.venueProfile.general.statusSwitchAltTooltip]: 'Only verified venues are viewable to the public',
  [tokens.pages.venueProfile.general.settingsHeader]: 'Basic Settings',
  [tokens.pages.venueProfile.general.entranceFeeSub]: 'Amount paid in advance to entry the venue (standard R$ {{ value }})',
  [tokens.pages.venueProfile.general.minAmountSub]: 'Minimum order amount (standard R$ {{ value }})',
  [tokens.pages.venueProfile.menu.productDialog.subtitle]: 'Set an specific regular price, sales price and stock quantity to this venue',
  [tokens.pages.venueProfile.layout.header]: 'Table Layout',
  [tokens.pages.venueProfile.inventory.entryDialog.subtitle]: 'Record a stock product entry',
  [tokens.pages.venueProfile.inventory.historyDialog.subtitle]: 'History of stock entry and exit per',
  [tokens.pages.venueProfile.security.altHeader]: 'Unable to modify your own access to venues',
  [tokens.pages.venueProfile.security.header]: 'Users with access to this venue',
  [tokens.pages.venueProfile.security.listAltHeader]: 'Authorized Users',
  [tokens.pages.venueProfile.security.listHeader]: 'Unauthorized Users',
  [tokens.pages.verifyCode.formHeader]: 'Code successfully sent!',
  [tokens.pages.verifyCode.formSubtitleStart]: 'We sent a 6-digit code to your email',
  [tokens.pages.verifyCode.formSubtitleEnd]: 'Type the code below to reset your password.',
  [tokens.pages.verifyCode.formFooter]: `Didn't receive the code?`,
  [tokens.pages.verifiedEmail.header]: 'Email successfully verified!',
  [tokens.popover.tenant.header]: 'Focused Management',
  [tokens.popover.tenant.subtitle]: 'Select a venue to see its environment',
  [tokens.popover.tenant.generalTitle]: 'Global Management',
  [tokens.roles.admin]: 'Admin',
  [tokens.roles.bartender]: 'Bartender',
  [tokens.roles.cook]: 'Cook',
  [tokens.roles.investor]: 'Investor',
  [tokens.roles.manager]: 'Manager',
  [tokens.settings.themeMode]: 'Mode',
  [tokens.snackbar.codeValidation.error]: 'Error while sending code',
  [tokens.snackbar.codeValidation.success]: 'Code sent successfully!',
  [tokens.snackbar.codeValidation.error]: 'Error while validating code',
  [tokens.snackbar.codeValidation.success]: 'Code validated successfully!',
  [tokens.snackbar.create.error]: 'Error while creating',
  [tokens.snackbar.create.success]: 'Successfully created!',
  [tokens.snackbar.delete.error]: 'Error while deleting',
  [tokens.snackbar.delete.success]: 'Successfully deleted!',
  [tokens.snackbar.notification.error]: 'Error while sending notification',
  [tokens.snackbar.notification.success]: 'Notification successfully sent!',
  [tokens.snackbar.passwordChange.error]: 'Error while changing password',
  [tokens.snackbar.passwordChange.success]: 'Successfully changed password!',
  [tokens.snackbar.resetPassword.error]: 'Error while reseting password',
  [tokens.snackbar.resetPassword.success]: 'Password successfully reseted!',
  [tokens.snackbar.resetPasswordEmail.error]: 'Error while sending email to reset password',
  [tokens.snackbar.resetPasswordEmail.success]: 'Password reset email successfully sent to {{ email }}',
  [tokens.snackbar.signUp.error]: 'Error while joining waitlist',
  [tokens.snackbar.signUp.success]: 'Waitlist successfully joined!',
  [tokens.snackbar.update.error]: 'Error while updating data',
  [tokens.snackbar.update.success]: 'Data successfully updated!',
  [tokens.snackbar.verifyEmail.error]: 'Error while sending email verification',
  [tokens.snackbar.verifyEmail.success]: 'Email verification successfully sent to {{ email }}',
  [tokens.status.label.active]: 'Active',
  [tokens.status.label.analyzing]: 'In analysis',
  [tokens.status.label.approved]: 'Approved',
  [tokens.status.label.canceled]: 'Canceled',
  [tokens.status.label.closed]: 'Closed',
  [tokens.status.label.completed]: 'Completed',
  [tokens.status.label.connected]: 'Connected',
  [tokens.status.label.declined]: 'Declined',
  [tokens.status.label.disconnected]: 'Disconnected',
  [tokens.status.label.inactive]: 'Inactive',
  [tokens.status.label.inStock]: 'In Stock',
  [tokens.status.label.lowStock]: 'Low Stock',
  [tokens.status.label.onSale]: 'On Sale',
  [tokens.status.label.open]: 'Open',
  [tokens.status.label.outOfStock]: 'Out of Stock',
  [tokens.status.label.pending]: 'Pending',
  [tokens.status.label.preparing]: 'Preparing',
  [tokens.status.label.ready]: 'Ready for pick up',
  [tokens.status.label.received]: 'Received',
  [tokens.status.label.reconnecting]: 'Reconnecting',
  [tokens.status.label.sent]: 'Sent',
  [tokens.status.step.cancel]: 'Cancel',
  [tokens.status.step.complete]: 'Complete',
  [tokens.status.step.finishPrep]: 'Finish preparing',
  [tokens.status.step.receive]: 'Receive',
  [tokens.status.step.startPrep]: 'Start preparing',
  [tokens.status.stepAction.orderCanceled]: 'Order canceled',
  [tokens.status.stepAction.orderReceived]: 'Order received',
  [tokens.status.stepAction.startedPreparation]: 'Started preparation',
  [tokens.status.stepAction.finishedPreparation]: 'Finished preparation',
  [tokens.status.stepAction.delivered]: 'Delivered to client',
  [tokens.tables.openVenues]: 'Open Venues',
  [tokens.tables.noOpenVenues]: 'No Open Venues Found',
  [tokens.tables.productRanking]: 'Product Ranking',
  [tokens.tags.containsAlcohol]: 'Contains alcohol',
  [tokens.tags.nonAlcoholic]: 'Non-alcoholic',
  [tokens.tags.type]: 'Type',
  [tokens.venueTypes.bar]: 'Bar',
  [tokens.venueTypes.events]: 'Events',
  [tokens.venueTypes.nightClub]: 'Night Club',
  [tokens.venueTypes.restaurant]: 'Restaurant',
  [tokens.weekdays.monday]: 'Monday',
  [tokens.weekdays.tuesday]: 'Tuesday',
  [tokens.weekdays.wednesday]: 'Wednesday',
  [tokens.weekdays.thursday]: 'Thursday',
  [tokens.weekdays.friday]: 'Friday',
  [tokens.weekdays.saturday]: 'Saturday',
  [tokens.weekdays.sunday]: 'Sunday',
};

export default en;
