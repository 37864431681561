import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from 'src/guards/RoleBasedGuard';
// contexts
import { SocketProvider } from '../contexts/SocketContext';
// config
import { PATH_AFTER_LOGIN, PATH_AFTER_BARTENDER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {

  const { user } = useAuth();

  const ROLE_BASED_PATH_AFTER_LOGIN = user && user.role === 'bartender' ? PATH_AFTER_BARTENDER_LOGIN : PATH_AFTER_LOGIN

  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        {
          path: 'sign-up',
          element: (
            <GuestGuard>
              <SignUp />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'invite/set-password', element: <InvitationNewPassword /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password/:resetToken', element: <NewPassword /> },
        { path: 'new-password/verify/:verifyToken', element: <VerifyNewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
        { path: 'users/verify-email', element: <VerifyEmail /> },
        { path: 'venueusers/verify-email', element: <VerifyEmail /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <SocketProvider>
            <DashboardLayout />
          </SocketProvider>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={ROLE_BASED_PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app',
          element: 
            <RoleBasedGuard hasContent roles={['admin', 'manager']}>
              <GeneralApp /> 
            </RoleBasedGuard>
        },
        // { path: 'ecommerce', element: <GeneralEcommerce /> },
        // { path: 'analytics', element: <GeneralAnalytics /> },
        // { path: 'banking', element: <GeneralBanking /> },
        // { path: 'booking', element: <GeneralBooking /> },
        {
          path: 'menu',
          children: [
            { element: <Navigate to="/dashboard/menu/list" replace />, index: true },
            // { path: 'shop', 
            //   element:
            //     <RoleBasedGuard hasContent roles={['admin', 'manager']}>
            //       <EcommerceShop />
            //     </RoleBasedGuard>
            // },
            { path: 'product/:name', 
              element:
                <RoleBasedGuard hasContent roles={['admin', 'manager']}>
                  <EcommerceProductDetails />
                </RoleBasedGuard>
            },
            { path: 'list', 
              element:
                <RoleBasedGuard hasContent roles={['admin', 'manager']}>
                  <MenuList />
                </RoleBasedGuard>
            },
            { path: 'product/new', 
              element:
                <RoleBasedGuard hasContent roles={['admin', 'manager']}>
                  <EcommerceProductCreate />
                </RoleBasedGuard>
            },
            { path: 'product/:id/edit', 
              element:
                <RoleBasedGuard hasContent roles={['admin', 'manager']}>
                  <EcommerceProductEdit />
                </RoleBasedGuard>
            },
            // { path: 'checkout', 
            //   element:
            //     <RoleBasedGuard hasContent roles={['admin', 'manager']}>
            //       <EcommerceCheckout />
            //     </RoleBasedGuard>
            // },
            { path: 'products', 
              element:
                <RoleBasedGuard hasContent roles={['admin', 'manager']}>
                  <EcommerceProductList />
                </RoleBasedGuard>
            },
            { path: 'categories', 
              element:
                <RoleBasedGuard hasContent roles={['admin', 'manager']}>
                  <MenuCategorytList />
                </RoleBasedGuard>
            },
            { path: 'category/new', 
              element:
                <RoleBasedGuard hasContent roles={['admin', 'manager']}>
                  <MenuCategorytCreate />
                </RoleBasedGuard>
            },
            { path: 'category/:name/edit', 
              element:
                <RoleBasedGuard hasContent roles={['admin', 'manager']}>
                  <MenuCategorytCreate />
                </RoleBasedGuard>
            },
          ],
        },
        {
          path: 'venue',
          children: [
            { element: <Navigate to="/dashboard/venue/cards" replace />, index: true },
            // { element: <Venues />, index: true },
            { path: 'profile', 
              element:
                <RoleBasedGuard hasContent roles={['admin', 'manager']}>
                  <VenueProfile />
                </RoleBasedGuard>
            },
            { path: 'cards', 
              element:
                <RoleBasedGuard hasContent roles={['admin', 'manager']}>
                  <VenueCards />
                </RoleBasedGuard>
            },
            { path: 'list', 
              element:
                <RoleBasedGuard hasContent roles={['admin', 'manager']}>
                  <VenueList />
                </RoleBasedGuard>
            },
            { path: 'schedule-table', 
              element:
                <RoleBasedGuard hasContent roles={['admin', 'manager']}>
                  <VenueTable />
                </RoleBasedGuard>
            },
            { path: 'new', 
              element:
                <RoleBasedGuard hasContent roles={['admin', 'manager']}>
                  <VenueCreate />
                </RoleBasedGuard>
            },
            { path: ':name/edit', 
              element:
                <RoleBasedGuard hasContent roles={['admin', 'manager']}>
                  <VenueProfile />
                </RoleBasedGuard>
            },
            { path: ':id/inventory/entry', 
            element:
              <RoleBasedGuard hasContent roles={['admin', 'manager']}>
                <InventoryCreate />
              </RoleBasedGuard>
          },
            // { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/list" replace />, index: true },
            { path: 'profile',
              element:
                <RoleBasedGuard hasContent roles={['admin', 'manager']}>
                  <UserProfile />
                </RoleBasedGuard>
            },
            { path: 'cards',
              element:
                <RoleBasedGuard hasContent roles={['admin', 'manager']}>
                  <UserCards />
                </RoleBasedGuard>
            },
            { path: 'list',
              element:
                <RoleBasedGuard hasContent roles={['admin', 'manager']}>
                  <UserList />
                </RoleBasedGuard>
            },
            { path: 'new',
              element:
                <RoleBasedGuard hasContent roles={['admin', 'manager']}>
                  <UserCreate />
                </RoleBasedGuard>
            },
            { path: ':name/edit',
              element:
                <RoleBasedGuard hasContent roles={['admin', 'manager']}>
                  <UserProfile />
                </RoleBasedGuard>
            },
            { path: 'account',
              element:
                <UserAccount />
            },
          ],
        },
        {
          path: 'order',
          children: [
            { element: <Navigate to="/dashboard/order/list" replace />, index: true },
            { path: 'list', element: <OrderList /> },
            { path: ':id', element: <OrderDetails /> },
            // { path: ':id/edit', element: <OrderEdit /> },
            // { path: 'new', element: <OrderCreate /> },
          ],
        },
        {
          path: 'inventory',
          children: [
            { element: <Navigate to="/dashboard/inventory/list" replace />, index: true },
            { path: 'control',
              element:
                <RoleBasedGuard hasContent roles={['admin', 'manager']}>
                  <InventoryControl />
                </RoleBasedGuard>
            },
            { path: 'statement',
              element:
                <RoleBasedGuard hasContent roles={['admin', 'manager']}>
                  <InventoryStatement />
                </RoleBasedGuard>
            },
            { path: 'create',
              element:
                <RoleBasedGuard hasContent roles={['admin', 'manager']}>
                  <InventoryCreate />
                </RoleBasedGuard>
            },
            // { path: ':id/create', 
            //   element:
            //     <RoleBasedGuard hasContent roles={['admin', 'manager']}>
            //       <InventoryCreate />
            //     </RoleBasedGuard>
            // },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/dashboard/invoice/kanban" replace />, index: true },
            { path: 'kanban',
              element:
                <RoleBasedGuard hasContent roles={['admin', 'manager']}>
                  <InvoiceKanban />
                </RoleBasedGuard>
            },
            { path: 'list',
              element:
                <RoleBasedGuard hasContent roles={['admin', 'manager']}>
                  <InvoiceList />
                </RoleBasedGuard>
            },
            { path: ':id',
              element:
                <RoleBasedGuard hasContent roles={['admin', 'manager']}>
                  <InvoiceDetails />
                </RoleBasedGuard>
            },
            { path: ':id/edit',
              element:
                <RoleBasedGuard hasContent roles={['admin', 'manager']}>
                  <InvoiceEdit />
                </RoleBasedGuard>
            },
            { path: 'new',
              element:
                <RoleBasedGuard hasContent roles={['admin', 'manager']}>
                  <InvoiceCreate />
                </RoleBasedGuard>
            },
          ],
        },
        // {
        //   path: 'security',
        //   children: [
        //     { element: <Navigate to="/dashboard/security/list" replace />, index: true },
        //     { path: 'role/list',
        //       element:
        //         <RoleBasedGuard hasContent roles={['admin', 'manager']}>
        //           <RoleList />
        //         </RoleBasedGuard>
        //     },
        //     { path: 'user/list',
        //       element:
        //         <RoleBasedGuard hasContent roles={['admin', 'manager']}>
        //           <VenueList />
        //         </RoleBasedGuard>
        //     },
        //     { path: 'venue/list',
        //       element:
        //         <RoleBasedGuard hasContent roles={['admin', 'manager']}>
        //           <VenueList />
        //         </RoleBasedGuard>
        //     },
        //     { path: 'group/list',
        //       element:
        //         <RoleBasedGuard hasContent roles={['admin', 'manager']}>
        //           <VenueList />
        //         </RoleBasedGuard>
        //     },
        //     { path: 'reports',
        //       element:
        //         <RoleBasedGuard hasContent roles={['admin', 'manager']}>
        //           <VenueList />
        //         </RoleBasedGuard>
        //     },
        //     { path: 'new',
        //       element:
        //         <RoleBasedGuard hasContent roles={['admin', 'manager']}>
        //           <VenueCreate />
        //         </RoleBasedGuard>
        //     },
        //     { path: ':name/edit',
        //       element:
        //         <RoleBasedGuard hasContent roles={['admin', 'manager']}>
        //           <VenueProfile />
        //         </RoleBasedGuard>
        //     },
        //   ],
        // },
        // {
        //   path: 'blog',
        //   children: [
        //     { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
        //     { path: 'posts', element: <BlogPosts /> },
        //     { path: 'post/:title', element: <BlogPost /> },
        //     { path: 'new', element: <BlogNewPost /> },
        //   ],
        // },
        // {
        //   path: 'mail',
        //   children: [
        //     { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
        //     { path: 'label/:customLabel', element: <Mail /> },
        //     { path: 'label/:customLabel/:mailId', element: <Mail /> },
        //     { path: ':systemLabel', element: <Mail /> },
        //     { path: ':systemLabel/:mailId', element: <Mail /> },
        //   ],
        // },
        // {
        //   path: 'chat',
        //   children: [
        //     { element: <Chat />, index: true },
        //     { path: 'new', element: <Chat /> },
        //     { path: ':conversationKey', element: <Chat /> },
        //   ],
        // },
        // { path: 'calendar', element: <Calendar /> },
        // { path: 'kanban', element: <Kanban /> },
        { path: 'permission-denied', element: <PermissionDenied /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        // { path: 'pricing', element: <Pricing /> },
        // { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <Navigate to={ROLE_BASED_PATH_AFTER_LOGIN} replace />, index: true },
        // { element: <HomePage />, index: true },
        // { path: 'about-us', element: <About /> },
        // { path: 'contact-us', element: <Contact /> },
        // { path: 'faqs', element: <Faqs /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const SignUp = Loadable(lazy(() => import('../pages/auth/SignUp')));
const InvitationNewPassword = Loadable(lazy(() => import('../pages/auth/InvitationAccept')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyNewPassword = Loadable(lazy(() => import('../pages/auth/VerifyCodeNewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
const VerifyEmail = Loadable(lazy(() => import('../pages/auth/VerifyEmail')));

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));

// ORDER
const OrderList = Loadable(lazy(() => import('../pages/dashboard/OrderList')));
const OrderDetails = Loadable(lazy(() => import('../pages/dashboard/OrderDetails')));
// const OrderCreate = Loadable(lazy(() => import('../pages/dashboard/OrderCreate')));
// const OrderEdit = Loadable(lazy(() => import('../pages/dashboard/OrderEdit')));

// PRODUCTS
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceProductEdit = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductEdit')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));
const MenuList = Loadable(lazy(() => import('../pages/dashboard/MenuList')))
const MenuCategorytList = Loadable(lazy(() => import('../pages/dashboard/MenuCategoryList')));
const MenuCategorytCreate = Loadable(lazy(() => import('../pages/dashboard/MenuCategoryCreate')));

// VENUES
const VenueProfile = Loadable(lazy(() => import('../pages/dashboard/VenueProfile')));
const VenueCards = Loadable(lazy(() => import('../pages/dashboard/VenueCards')));
const VenueList = Loadable(lazy(() => import('../pages/dashboard/VenueList')));
const VenueTable = Loadable(lazy(() => import('../pages/dashboard/VenueTable')));
const Venues = Loadable(lazy(() => import('../pages/dashboard/VenuesMain')));
// const VenueAccount = Loadable(lazy(() => import('../pages/dashboard/VenueAccount')));
const VenueCreate = Loadable(lazy(() => import('../pages/dashboard/VenueCreate')));

// INVENTORY
const InventoryCreate = Loadable(lazy(() => import('../pages/dashboard/InventoryCreate')));
const InventoryControl = Loadable(lazy(() => import('../pages/dashboard/InventoryControl')));
// const InventoryEdit = Loadable(lazy(() => import('../pages/dashboard/InventoryEdit')));
const InventoryStatement = Loadable(lazy(() => import('../pages/dashboard/InventoryStatement')));

// INVOICE
const InvoiceKanban = Loadable(lazy(() => import('../pages/dashboard/InvoiceKanban')));
const InvoiceList = Loadable(lazy(() => import('../pages/dashboard/InvoiceList')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/InvoiceDetails')));
const InvoiceCreate = Loadable(lazy(() => import('../pages/dashboard/InvoiceCreate')));
const InvoiceEdit = Loadable(lazy(() => import('../pages/dashboard/InvoiceEdit')));

// BLOG
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));

// USER
const CurrentUserProfile = Loadable(lazy(() => import('../pages/dashboard/CurrentUserProfile')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// APP
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));

// SECURITY
const RoleList = Loadable(lazy(() => import('../pages/dashboard/RoleList')))

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
