import { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Grid, RadioGroup, CardActionArea, Typography, FormControlLabel, Switch, Stack } from '@mui/material';
//form
import { useForm, Controller } from 'react-hook-form';
// hooks
import useSettings from '../../../hooks/useSettings';
import useLocales from '../../../hooks/useLocales';
import useResponsive from '../../../hooks/useResponsive';
// translations
import { tokens } from '../../../locales/tokens';
//
import Iconify from '../../../components/Iconify';
import BoxMask from '../../../components/settings/drawer/BoxMask';

// ----------------------------------------------------------------------

const BoxStyle = styled(CardActionArea)(({ theme }) => ({
  height: 54,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.disabled,
  border: `solid 1px ${theme.palette.grey[500_12]}`,
  borderRadius: Number(theme.shape.borderRadius) * 1.25,
}));

// ----------------------------------------------------------------------

export default function ThemeModeSwitch() {
  const { themeMode, onChangeMode, onToggleMode } = useSettings();

  const { t } = useLocales();

  const methods = useForm();

  const isDesktop = useResponsive('up', 'lg');

  const {
    control,
  } = methods;

  return (
    <RadioGroup
      name="themeMode"
      value={themeMode}
      onChange={onChangeMode} 
      sx={{ px: 2, textAlign: 'center', display: 'block' }}
    >
      <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1 }}>
        { isDesktop && (
          <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
            {t(tokens.settings.themeMode)}
          </Typography>
        )}

        <Iconify
          icon={'ph:sun-duotone'}
          color={themeMode !== 'light' ? 'common.white' : 'grey.800'}
          width={20}
          height={20}
        />

        <FormControlLabel
          labelPlacement='end'
          control={
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <Switch
                  {...field}
                  checked={themeMode !== 'light'}
                  onChange={onToggleMode}
                />
              )}
            />
          }
          label={''}
          sx={{ px: -1 }}
        />

        <Iconify
          icon={'ph:moon-duotone'}
          color={themeMode !== 'light' ? 'common.white' : 'grey.800'}
          width={20}
          height={20}
        />

        {/* <Grid dir="ltr" container spacing={2.5}>
          {['light', 'dark'].map((mode, index) => {
            const isSelected = themeMode === mode;

            return (
              <Grid key={mode} item xs={6}>

                <BoxStyle
                  sx={{
                    bgcolor: mode === 'light' ? 'common.white' : 'grey.800',
                    ...(isSelected && {
                      color: 'primary.main',
                      boxShadow: (theme) => theme.customShadows.z20,
                    }),
                  }}
                >
                  <Iconify
                    icon={index === 0 ? 'ph:sun-duotone' : 'ph:moon-duotone'}
                    width={28}
                    height={28}
                  />
                  <BoxMask value={mode} />
                </BoxStyle>

                <Typography variant="body2" sx={{ textAlign: 'center', display: 'block', color: 'text.secondary' }}>
                  {mode}
                </Typography>
              </Grid>
            );
          })}
        </Grid> */}
      </Stack>
    </RadioGroup>
  );
}
