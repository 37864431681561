import { KanbanBoard } from "src/@types/kanban";

export const board: KanbanBoard = {
    columnOrder: ['1', '2', '3', '4', '0'],
    columns: [
      {
        id: '1',
        name: 'received',
        cardIds: ['card-1', 'card-2'],
      },
      {
        id: '2',
        name: 'preparing',
        cardIds: ['card-3'],
      },
      {
        id: '3',
        name: 'ready',
        cardIds: ['card-4'],
      },
      {
        id: '4',
        name: 'completed',
        cardIds: ['card-5', 'card-6'],
      },
      {
        id: '0',
        name: 'canceled',
        cardIds: ['card-7'],
      },
    ],
    cards: [
      {
        id: 'card-1',
        name: 'Order 1',
        assignee: [],
        due: [null, null],
        attachments: [],
        comments: [],
        completed: false
      },
      {
        id: 'card-2',
        name: 'Order 2',
        assignee: [],
        due: [new Date(), new Date()],
        attachments: [],
        comments: [],
        completed: false
      },
      {
        id: 'card-3',
        name: 'Order 3',
        assignee: [],
        due: [new Date(), new Date()],
        attachments: [],
        comments: [],
        completed: false
      },
    {
      id: 'card-4',
      name: 'Order 4',
      assignee: [],
      due: [new Date(), new Date()],
      attachments: [],
      comments: [],
      completed: false
    },
    {
      id: 'card-5',
      name: 'Order 5',
      assignee: [],
      due: [new Date(), new Date()],
      attachments: [],
      comments: [],
      completed: true
    },
    {
      id: 'card-6',
      name: 'Order 6',
      assignee: [],
      due: [new Date(), new Date()],
      attachments: [],
      comments: [],
      completed: true
    },
    {
      id: 'card-7',
      name: 'Order 7',
      assignee: [],
      due: [new Date(), new Date()],
      attachments: [],
      comments: [],
      completed: true
    }
    ],
    }